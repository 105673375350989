import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts';
import SEO from '../components/SEO';

const Error = () => (
  <Layout>
    <SEO title="Something went wrong." />
    <main className="error-page">
      <div className="error-container">
        <h1>opps it's dead end</h1>
        <Link to="/" className="btn">
          Back home
        </Link>
      </div>
    </main>
  </Layout>
);

export default Error;
